import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import FAQPanel from "../components/faq-panel/faq-panel.component";
import AffordabilityCalculator from "../components/affordability-calculator/affordability-calculator.component";
import PopularSearches from "../components/popular-searches/popular-searches.component";
import WhatHasChanged from "../components/what-has-changed/what-has-changed.component";
import AccordionGroup from "../components/accordion-group/accordion-group.component";
import FilterBar from "../components/filter-bar/filter-bar.component";
import Tabs from "../components/tabs/tabs.component";
import FloatingBanner from "../components/floating-banner/floating-banner.component";

import { MenuContext } from "../context";

import { RESIDENTAL_DATA_SCHEME } from "../data/criteria.data";

import { WHC_MONTHS, getTermByTitle } from "../components/what-has-changed/what-has-changed.utils";

import "../styles/app.scss";

const resultWord = (result) => {
  return result < 2 ? "result" : "results";
};

const Result = ({ result, searchTerm }) => (
  <h2 role="status" aria-atomic="true" className="result">
    {result !== null && searchTerm !== "" ? (
      <>
        Showing {result} {resultWord(result)} for <span>{searchTerm}</span>
      </>
    ) : null}
  </h2>
);

const decodeQueryParam = (param) => decodeURIComponent(param.replace(/\+/g, " "));

export default function Criteria(props) {
  const [filterKey, setFilterKey] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [foundItemsCount, setFoundItemsCount] = useState(0);
  const [tabData, setTabData] = useState([[], []]);

  const menuContext = useContext(MenuContext);

  const handleClick = (event) => {
    if (
      (event.target && event.target.hasAttribute("data-tealium")) ||
      (event.target.parentNode && event.target.parentNode.hasAttribute("data-tealium"))
    ) {
      if (typeof window !== "undefined") {
        if (window.utag) {
          window.utag.link({ page_subcategory: "criteria" });
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const scrollToToggle = useCallback(() => {
    let url = new URL(window.location);
    let scrollId = url.searchParams.get("p") || "";
    // scroll to accordion
    if (scrollId) {
      // EDGE CASE: after first #key-word accordion click,
      // click works like redirect and reset tab to 0;
      // so we need to wait after tab change
      setTimeout(() => {
        const divElement = document.getElementById(scrollId);
        if (divElement) {
          divElement.setAttribute("open", true);
          setTimeout(() => {
            divElement.scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      }, 600);

      if (typeof window !== `undefined`) {
        window.history.pushState("criteria", "Title", "/criteria");
      }
    }
  }, []);

  const crossSiteLinking = useEffect(() => {
    if (typeof window !== `undefined`) {
      let url = new URL(window.location);
      let scrollId = url.searchParams.get("p") || "";
      // scroll to accordion
      if (scrollId) {
        // EDGE CASE: after first #key-word accordion click,
        // click works like redirect and reset tab to 0;
        // so we need to wait after tab change
        setTimeout(() => {
          const divElement = document.getElementById(scrollId);
          if (divElement) {
            divElement.setAttribute("open", true);
            setTimeout(() => {
              divElement.scrollIntoView({ behavior: "smooth" });
            }, 500);
          }
        }, 600);
      }
      window.history.pushState("criteria", "Title", "/criteria");
    }
  }, []);

  const onLocationChange = useCallback(
    (event) => {
      let url = new URL(document.location);
      let filterValue = url.searchParams.get("filter") || "";
      setFilterKey(filterValue);
      scrollToToggle();
      /* crossSiteLinking(); */
    },
    [scrollToToggle]
  );

  useEffect(() => {
    let { pathname, search } = props.location;
    if (pathname.includes("/criteria") && search.trim() === "") {
      updateFilter("");
    }
  }, [props.location]);

  useEffect(() => {
    window.addEventListener("popstate", onLocationChange);

    return () => {
      window.removeEventListener("popstate", onLocationChange);
    };
  }, [onLocationChange]);

  const getFilterBar = () => <FilterBar setFilter={updateFilter} filterKey={filterKey} />;

  const updateFilter = (filterValue) => {
    // update browser history
    const url = new URL(window.location);
    if (filterValue) {
      url.searchParams.set("filter", filterValue);
      url.searchParams.delete("p");
      url.searchParams.delete("tab");
      window.history.pushState({ filter: filterValue }, "", url);
    } else {
      url.searchParams.delete("filter");
      window.history.pushState({ filter: filterValue }, "", url);
    }

    // update filter
    setFilterKey(filterValue);
  };

  const addHandler = useCallback(() => {
    const ancors = [...document.querySelectorAll(`[data-accordion]`)];
    ancors.forEach(
      (ancor) =>
        (ancor.onclick = (event) => {
          event.preventDefault();
          let p = ancor.getAttribute("data-accordion");
          let tab = ancor.getAttribute("data-tab");
          const url = new URL(window.location);
          url.searchParams.set("p", p);
          url.searchParams.set("tab", tab);
          window.history.pushState({}, "", url);
          // need to change tab before scroll

          if (tab === "buy-to-let") {
            setSelectedTabIndex(1);
          } else {
            // residential tab
            setSelectedTabIndex(0);
          }
          scrollToToggle();
        })
    );
  }, [scrollToToggle]);

  const oversizedTable = useCallback(() => {
    let table1 = document.querySelector("#otc-1");
    let table2 = document.querySelector("#otc-2");

    if (table1) {
      table1.addEventListener("mouseenter", function () {
        table1.classList.add("active");
      });
      table1.addEventListener("mouseleave", function () {
        table1.classList.remove("active");
      });
    }
    if (table2) {
      table2.addEventListener("mouseenter", function () {
        table2.classList.add("active");
      });
      table2.addEventListener("mouseleave", function () {
        table2.classList.remove("active");
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const url = new URL(window.location);
      const currentTab = url.searchParams.get("tab");
      if (currentTab === "buy-to-let") {
        setSelectedTabIndex(1);
      } else {
        // residential tab
        setSelectedTabIndex(0);
      }
    }, 500);
  }, []);

  /**
   * @name changeTab
   *
   * Change the selected tab
   * @param index this is the number which tab we would like to select. We have to tab for at the moment.0 = residential, 1 = let
   */
  const changeTab = useCallback(
    (index) => {
      let filteredData = [RESIDENTAL_DATA_SCHEME[0], RESIDENTAL_DATA_SCHEME[1]];
      let foundItems = [0, 0];

      filteredData[index] = filteredData[index].reduce((acc, accordionGroup) => {
        const accordionList = accordionGroup.accordionList.filter(({ filterTerm }) => {
          // replace What Has Changed title with term on search only
          if (WHC_MONTHS.includes(filterKey)) {
            return filterTerm.includes(getTermByTitle(filterKey));
          }
          return filterTerm.includes(filterKey);
        });

        if (accordionList.length) {
          foundItems[index] += accordionList.length;
          acc.push({ ...accordionGroup, accordionList });
          setFoundItemsCount(foundItems);
        }
        if (accordionList.length === 0) {
          foundItems[index] += accordionList.length;
          setFoundItemsCount(foundItems);
        }

        return acc;
      }, []);

      setTabData(filteredData);
      setSelectedTabIndex(index);

      // add click handlers on tab change for new accordions
      setTimeout(addHandler, 1000);
      setTimeout(oversizedTable, 1000);
    },
    [filterKey, addHandler, oversizedTable]
  );

  //Buy To Let filtering by tags
  //all accordions of BTL
  const buyToLetAccordionList = RESIDENTAL_DATA_SCHEME[1].map((e) => {
    return e.accordionList;
  });
  //merging into an array
  const mergedBTLAccordionList = [].concat.apply([], buyToLetAccordionList);
  //filter only for those who has filterTerm
  const allFilterTerms = mergedBTLAccordionList.filter((e) => {
    return e.filterTerm;
  });
  //getting all tags and merge into one array
  const tags = allFilterTerms.map((e) => e.filterTerm);

  let mergedTags = [].concat.apply([], tags);
  //TODO Plese find a better solution for this: replace [2] and [3], etc.
  let splitApplicantData = mergedTags[2].split(",");
  let splitBorrowingData = mergedTags[3].split(",");
  mergedTags = mergedTags.concat(splitApplicantData, splitBorrowingData);

  useEffect(() => {
    //Load the filter from url for the first load
    const url = new URL(window.location);
    let filterValue = url.searchParams.get("filter") || "";

    filterValue.toString();

    if (!mergedTags.includes(filterValue) || filterValue === "income" || filterValue === "mortgage") {
      url.searchParams.delete("p");
      url.searchParams.delete("tab");
      setFilterKey(decodeQueryParam(filterValue));
      changeTab(0);
    } else if (mergedTags.includes(filterValue)) {
      changeTab(1);
    }
  }, [changeTab]);

  return (
    <>
      <Layout
        title="Criteria"
        metaDescription="To help you find what you\'re looking for, we've grouped our lending criteria into key categories. You can browse by category or use the search function to jump straight to the relevant entry."
      >
        <FloatingBanner isSticky="true" />
        <section className="criteria-page page-section row">
          <div className="information-container col-xs-12 col-lg-7">
            <h1 className="page-title">Criteria</h1>
            <p>
              To help you find what you're looking for, we've grouped our lending criteria into key categories. You can
              browse by category or use the search function to jump straight to the relevant entry.
            </p>
            <WhatHasChanged setFilter={updateFilter} />
            {getFilterBar()}
            <Result result={foundItemsCount[selectedTabIndex]} searchTerm={filterKey} />
            <Tabs
              activeTabIndex={selectedTabIndex}
              setActiveTab={changeTab}
              tabs={["Residential criteria", "Buy to let criteria"]}
              tabContent={[
                tabData[0].map((groupData, index) => (
                  <AccordionGroup {...groupData} key={`res_${index}`} isTealiumLink={true} />
                )),
                tabData[1].map((groupData, index) => (
                  <AccordionGroup {...groupData} key={`buy_${index}`} isTealiumLink={true} />
                )),
              ]}
            />
          </div>
          <div className="support-container col-xs-12 col-lg-4 col-lg-offset-1 margin-top-70">
            <div className="criteria-btn">
              <Link to="/packaging-requirements" inert={menuContext.isMenuOpen ? "true" : null} data-tealium>
                Packaging requirements
              </Link>
            </div>
            <div className="criteria-btn criteria-btn--light-green">
              <Link to="/resources" inert={menuContext.isMenuOpen ? "true" : null} data-tealium>
                Resources
              </Link>
            </div>
            <PopularSearches setSearch={updateFilter} />
            <AffordabilityCalculator>
              <p className="calculator-description">
                The calculator is designed to give you an accurate projection of the amount we may be able to lend to
                your customer.
              </p>
              <p className="calculator-description">
                <strong>Please note:</strong> the affordability calculator will not accurately support additional
                borrowing requests or multiple mortgage terms at this time.
              </p>
            </AffordabilityCalculator>
            {/* show FAQ on Residential criteria tab only */}
            {selectedTabIndex === 0 && <FAQPanel />}
          </div>
        </section>
      </Layout>
    </>
  );
}
